<template>
  <div id="role">
    <v-container grid-list-xl fluid>
      <header-title title="Roles"></header-title>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="showDialog()">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="roles"
        :loading="loading"
        :search="search"
        calculate-widths
        no-data-text="No existen registros"
        no-results-text="Sin resultados"
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <template v-slot:[`item.estado`]="{ item }">
          <td>
            <span
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
            >
              <v-chip small color="green lighten-4">activo</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">inactivo</v-chip>
            </span>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="editRole(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  color="red"
                  @click="confirmItem(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="mdialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Rol</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Tipo de roles"
                    :items="roleTypes"
                    v-model="role.tipo_rol_id"
                    item-text="descripcion"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="text"
                    label="Sigla *"
                    v-model="role.sigla"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="text"
                    label="Descripción *"
                    v-model="role.descripcion"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Estado"
                    :items="states"
                    v-model="role.estado"
                    item-text="descripcion"
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="mode"
              @click.native="createRole()"
              >Guardar</v-btn
            >
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="!mode"
              @click.native="updateRole()"
              >Actualizar</v-btn
            >
            <v-btn color="red" text @click.native="mdialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="mconfirm" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de eliminar el registro?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="btn_loading"
            text
            @click="deleteItem()"
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="mconfirm = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
    >
      {{ snack.text }}
      <!-- <v-btn dark text @click="snack.state = false">Cerrar</v-btn> -->
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "role",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      search: "",
      loading: false,
      btn_loading: false,
      headers: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Tipo de rol ", value: "tipo_rol.descripcion" },
        { text: "Sigla ", value: "sigla" },
        { text: "Descripción ", value: "descripcion" },
        { text: "Estado ", value: "estado" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      roles: [],
      roleTypes: [],
      role: {
        id: {
          type: Number,
          value: null,
        },
        tipo_rol_id: {
          type: Number,
          value: "",
        },
        sigla: "",
        descripcion: "",
        estado: {
          type: Boolean,
          value: null,
        },
      },
      states: [],
      mdialog: false,
      mconfirm: false,
      mode: true,
      snack: {
        state: false,
        color: "success",
        text: "",
      },
    };
  },
  created() {
    this.getRoleTypes();
  },
  mounted() {
    const user = Service.getUser();
    if (user && user.role == 1) {
      this.states = Service.getStates();
      this.getRoles();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    getRoleTypes() {
      axios
        .get(Service.getBase() + "parametro/tipo/rol", Service.getHeader())
        .then((response) => {
          this.roleTypes = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    getRoles() {
      this.loading = true;
      axios
        .get(Service.getBase() + "roles", Service.getHeader())
        .then((response) => {
          this.loading = false;
          this.roles = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDialog() {
      this.role = {
        id: "",
        tipo_rol_id: null,
        sigla: "",
        descripcion: "",
        estado: true,
      };
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.mode = true;
      this.mdialog = true;
    },
    createRole() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        axios
          .post(Service.getBase() + "rol", this.role, Service.getHeader())
          .then((response) => {
            this.btn_loading = false;
            this.toast("success", response.data);
            this.mdialog = false;
            this.getRoles();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast("error", "No se pudo guardar");
          });
      }
    },

    editRole(data) {
      this.role = data;
      this.mode = false;
      this.mdialog = true;
    },

    updateRole() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        axios
          .put(
            Service.getBase() + "rol/" + this.role.id,
            this.role,
            Service.getHeader()
          )
          .then((response) => {
            this.btn_loading = false;
            this.toast("success", response.data);
            this.mdialog = false;
            this.getRoles();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast("error", "No se pudo actualizar");
          });
      }
    },

    confirmItem(id) {
      this.role.id = id;
      this.mconfirm = true;
    },

    deleteItem() {
      this.btn_loading = true;
      axios
        .delete(Service.getBase() + "rol/" + this.role.id, Service.getHeader())
        .then((response) => {
          this.btn_loading = false;
          if (response.status === 204) {
            this.toast("success", "Registro eliminado");
            this.mconfirm = false;
            this.getRoles();
          } else {
            this.toast("error", "No se elimino el registro");
          }
        })
        .catch(() => {
          this.btn_loading = false;
          this.toast("error", "Registro en uso o no existente");
        });
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
